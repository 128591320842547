import { t } from '@lingui/macro'
import { useRouter } from 'next/router'
import { FC, useContext, useState } from 'react'
import { useMediaQuery } from 'react-responsive'

import LogoIcon from '@promova/ui/icons/diya.svg'

import { useAppSelector } from '@reduxStore/hooks'

import {
  FREE_FOR_UKRAINE_ROUTE,
  SHOW_FREE_FOR_UKRAINE_BUTTON,
} from '@constants'

import { getRmBoolValue } from '@utils/getRmBoolValue'

import AdaptiveText from '@features/FunnelBuilder/components/common/onboarding/AdaptiveText/AdaptiveText'

import { AuthContext } from '@modules/Auth/AuthProvider'
import { useRemoteConfig } from '@modules/RemoteConfig/RemoteConfigProvider'

import FreeUkraineShortInfo from '@elements/FreeUkraineShortInfo/FreeUkraineShortInfo'

import styles from './ukraine_btn.module.scss'

interface Props {
  onClick?: (...args: any[]) => void
  className?: string
  useDropdown?: boolean
}

const hideUkButtonRoutes = ['/free-for-ukraine']

const FreeUkraineButton: FC<Props> = (props) => {
  const { onClick, className = '', useDropdown = true } = props
  const isDesktop = useMediaQuery({
    query: '(min-width: 1100px)',
  })
  const router = useRouter()
  const [open, setOpen] = useState(false)
  const { user, loading } = useContext(AuthContext)

  const hasCoursesPremium = useAppSelector(
    (state) => state.profile.hasCoursesPremium
  )
  const isSubscriptionFetched = useAppSelector(
    (state) => state.profile.isSubscriptionFetched
  )

  const { remoteConfig } = useRemoteConfig()
  const shouldShowBtn = getRmBoolValue(
    remoteConfig,
    SHOW_FREE_FOR_UKRAINE_BUTTON
  )
  const handleClose = () => {
    setOpen(false)
  }

  const toggleOpen = () => {
    setOpen((prevOpen) => {
      if (!prevOpen) {
        window.addEventListener('click', handleClose)
      } else {
        window.removeEventListener('click', handleClose)
      }
      return !prevOpen
    })
  }

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    e?.stopPropagation()

    if (useDropdown && isDesktop) {
      toggleOpen()
    } else if (useDropdown && !isDesktop) {
      router.push(FREE_FOR_UKRAINE_ROUTE)
    } else if (typeof onClick === 'function') {
      onClick()
    }
  }

  const hideButton = hideUkButtonRoutes.includes(router?.pathname)

  if (
    !shouldShowBtn ||
    loading ||
    (user && !isSubscriptionFetched) ||
    hasCoursesPremium ||
    hideButton
  ) {
    return null
  }

  return (
    <div className={styles.dropdown_container}>
      <button
        type="button"
        onClick={handleClick}
        className={`${styles.ukraine_btn} ${className}`}
      >
        <LogoIcon className={styles.btn_log} />

        <AdaptiveText
          text={t`Free for Ukrainians`}
          initialFontSize={13}
          maxHeight={30}
          maxRowsAllowed={2}
          step={1}
        />
      </button>
      {useDropdown && isDesktop && open && (
        <div className={styles.dropdown}>
          <FreeUkraineShortInfo />
        </div>
      )}
    </div>
  )
}

export default FreeUkraineButton
