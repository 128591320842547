import { Trans } from '@lingui/macro'
import Link from 'next/link'
import { FC, useContext, useState } from 'react'

import Icon from '@promova/ui/components/Icon/Icon'
import LogoIcon from '@promova/ui/icons/diya.svg'

import { useAppDispatch, useAppSelector } from '@reduxStore/hooks'
import { updateKeeper } from '@reduxStore/reducers/profile/profileThunks'

import {
  FREE_FOR_UKRAINE_ROUTE,
  SHOW_FREE_FOR_UKRAINE_BUTTON,
} from '@constants'

import useWebViewMode from '@hooks/useWebViewMode'

import { getRmBoolValue } from '@utils/getRmBoolValue'

import { AuthContext } from '@modules/Auth/AuthProvider'
import { useRemoteConfig } from '@modules/RemoteConfig/RemoteConfigProvider'

import styles from './fixed_ukraine_btn.module.scss'

const WAS_CLOSED_UKRAINE_BTN = 'was_closed_ukraine_btn'
const MAX_SHOW_TIMES = 2

const FreeForUkraineFixedBtn: FC = () => {
  const { isWebViewMode } = useWebViewMode()
  const { remoteConfig } = useRemoteConfig()
  const shouldShowBtn = getRmBoolValue(
    remoteConfig,
    SHOW_FREE_FOR_UKRAINE_BUTTON
  )

  const hasCoursesPremium = useAppSelector(
    (state) => state.profile.hasCoursesPremium
  )
  const isSubscriptionFetched = useAppSelector(
    (state) => state.profile.isSubscriptionFetched
  )
  const { user, loading } = useContext(AuthContext)
  const dispatch = useAppDispatch()
  const { userProfile } = useAppSelector((state) => state.profile)

  const wasClosedCountData = userProfile?.keeper?.[WAS_CLOSED_UKRAINE_BTN]
  const wasClosedCount = wasClosedCountData
    ? +JSON.parse(wasClosedCountData.value)
    : 0

  const [wasClosed, setWasClosed] = useState(
    typeof window === 'undefined'
      ? false
      : sessionStorage?.getItem(WAS_CLOSED_UKRAINE_BTN) === 'true'
  )

  const handleClose = async () => {
    setWasClosed(true)
    window?.sessionStorage?.setItem(WAS_CLOSED_UKRAINE_BTN, 'true')

    const token = await user?.getIdToken()
    if (!token) return

    dispatch(
      updateKeeper({
        token,
        data: {
          key: WAS_CLOSED_UKRAINE_BTN,
          payload: String(wasClosedCount + 1),
        },
      })
    )
  }

  if (
    !shouldShowBtn ||
    loading ||
    (user && !isSubscriptionFetched) ||
    hasCoursesPremium ||
    wasClosed ||
    wasClosedCount >= MAX_SHOW_TIMES ||
    isWebViewMode
  ) {
    return null
  }

  return (
    <div className={styles.fixed_btn}>
      <Link href={FREE_FOR_UKRAINE_ROUTE} passHref>
        {/* eslint-disable-next-line */}
        <a className={styles.flex_row} onClick={handleClose}>
          <LogoIcon className={styles.btn_log} />
          <div className={styles.btn_text}>
            <Trans>Free for Ukrainians</Trans>
          </div>
          <Icon icon="angle_right" />
        </a>
      </Link>
      <button
        onClick={handleClose}
        aria-label="cancel button"
        type="button"
        className={styles.cancel_btn}
      >
        <Icon icon="close" size={16} className={styles.cancel_icon} />
      </button>
    </div>
  )
}

export default FreeForUkraineFixedBtn
