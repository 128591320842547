import classNames from 'classnames'
import { FC } from 'react'

import styles from './avatar.module.scss'

interface Props {
  url: string
  size?: number
  className?: string
}

const Avatar: FC<Props> = (props) => {
  const { url, size = 45, className = '' } = props

  const classList = classNames(styles.avatar, {
    [className]: !!className,
  })

  return (
    <div
      className={classList}
      style={{
        backgroundImage: `url(${url})`,
        width: `${size}px`,
        height: `${size}px`,
      }}
    />
  )
}

interface PropsWithName extends Props {
  name: string
  textClassName?: string
}
export const AvatarWithName: FC<PropsWithName> = (props) => {
  const { name, textClassName, ...rest } = props

  return (
    <div className={styles.avatar_with_name}>
      <Avatar {...rest} />
      <div className={classNames(styles.avatar_name, textClassName)}>
        {name}
      </div>
    </div>
  )
}

export default Avatar
