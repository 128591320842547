export const stringToBool = (val: string | boolean): boolean =>
  val === 'true' || val === true

export const getRmBoolValue = (
  config: Record<string, any>,
  key: string
): boolean => {
  const remoteValue = config[key]
  if (remoteValue === undefined) return false
  let value = false

  try {
    const parsedValue = JSON.parse(remoteValue)

    if (typeof parsedValue === 'object') {
      value = stringToBool(parsedValue.value)
    } else if (typeof parsedValue === 'boolean') {
      value = parsedValue
    }
  } catch (error) {
    value = false
  }

  return value
}
