import classNames from 'classnames'
import { CSSProperties, FC } from 'react'

import useFitText from '@utils/useFitText'

import styles from './adaptive_text.module.scss'

/**
 * This component dynamically adjusts the font size to fit the provided text content in container.
 *
 * @prop text (string): The text content to display.
 * @prop initialFontSize (number): The starting font size for the text.
 * @prop className (string): (Optional) A CSS class name to style the text.
 * @prop textLineHeightPercent (number): (Optional) The line height as a percentage of the font size.
 * @prop maxHeight (number): (Optional) Set a maximum height for the containing element.
 * @prop maxRowsAllowed (number): (Optional) Define the maximum number of text lines allowed.
 * @prop step (number): (Optional) Adjust the increment/decrement step size for font size adjustments (defaults to 6).
 *
 * @note This component uses dangerouslySetInnerHTML, so ensure proper sanitization of user-provided text.
 * @note You can use two approaches:
 *        when component dynamically calculate container height (you need to pass initialFontSize, maxRowsAllowed and textLineHeightPercent if needed)
 *        when you pass maxHeight of container (just pass maxHeight prop)
 */

type Props = {
  text: string
  className?: string
  initialFontSize: number
  textLineHeightPercent?: number
  maxHeight?: number
  maxRowsAllowed?: number
  step?: number
  style?: CSSProperties
  containerStyle?: CSSProperties
  containerClassName?: string
}

const AdaptiveText: FC<Props> = ({
  text,
  className,
  maxHeight,
  initialFontSize,
  step = 6,
  maxRowsAllowed,
  textLineHeightPercent = 100,
  style,
  containerStyle,
  containerClassName,
}) => {
  const totalFontSize = initialFontSize * (textLineHeightPercent / 100)
  const containerHeightAllRows =
    maxRowsAllowed && maxRowsAllowed * totalFontSize
  const fallbackContainerHeight = maxHeight ?? totalFontSize
  const maxContainerHeight = containerHeightAllRows ?? fallbackContainerHeight

  const { fontSize, textRef, containerRef } = useFitText<
    HTMLDivElement,
    HTMLHeadingElement
  >({
    initialFontSize,
    step,
  })

  const containerClassNames = classNames(styles.container, containerClassName)

  return (
    <div
      ref={containerRef}
      style={{
        maxHeight: maxContainerHeight,
        fontSize,
        ...containerStyle,
      }}
      className={containerClassNames}
    >
      <p
        ref={textRef}
        style={style}
        className={className}
        dangerouslySetInnerHTML={{ __html: text }}
      />
    </div>
  )
}

export default AdaptiveText
